import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "gameplay"
    }}>{`Gameplay`}</h1>
    <hr></hr>
    <h3 {...{
      "id": "exploring"
    }}>{`Exploring`}</h3>
    <p>{`Exploring Icon will be enabled when you accept a quest only ( If you didn’t accept a quest or the character is in cooldown period, you cannot send such character to explore ).`}</p>
    <p><img alt="explore" src={require("./public/images/explore-1.png")} /></p>
    <p>{`When you start exploring, your character will collect the materials that you selected. If your character is a new NFT that has never gone exploring before, the first exploration will be cost-free`}</p>
    <p><img alt="explore" src={require("./public/images/explore-2.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      